import styled from "styled-components";

const HorizontalLine = styled.div`
  background-color: ${({ theme }) => theme.horizontalLine.color};
  width: 100%;
  height: 1px;
  margin: 20px 0;
`;

export default HorizontalLine;
