import React from "react";
import {createGlobalStyle} from 'styled-components';
import PropTypes from "prop-types";
import proximanova from './Proxima_Nova/ProximaNova-Regular.ttf';
import proximanovabold from './Proxima_Nova/ProximaNova-Bold.ttf';
import sourcesanspro from './Source_Sans_Pro/SourceSansPro-Regular.ttf';
import sourcesansprobold from './Source_Sans_Pro/SourceSansPro-SemiBold.ttf';
import lato from './Lato/Lato-Regular.ttf';
import latobold from './Lato/Lato-Bold.ttf';
import arial from './Arial/arial.ttf';
import arialbold from './Arial/arialbd.ttf'
import avenir from './Avenir_Black/Avenir-Black.ttf';
import avenirbook from './Avenir_Black/Avenir-Book.ttf'
import helveticaneuebold from './Helvetica_Neue/NeueHelveticaBQ-Bold.ttf'
import opensans from './Open_Sans/OpenSans-Regular.ttf'
import montserrat from './Montserrat/Montserrat-Medium.ttf'
import montserratsemibold from './Montserrat/Montserrat-SemiBold.ttf'
import merriweatherbold from './Merriweather/Merriweather-Bold.ttf'


const Fonts = createGlobalStyle`

    @font-face {
        font-family: 'proximanova-bold, sans-serif';
        src: local('Proxima Nova Bold'), local('ProximaNovaBold'),
        url(${proximanovabold}) format('truetype');
    };

    @font-face {
        font-family: 'proximanova-regular, sans-serif';
        src: local('Proxima Nova'), local('ProximaNova'),
        url(${proximanova}) format('truetype');
    };

    @font-face {
        font-family: 'Source Sans Pro';
        src: local('Source Sans Pro'), local('SourceSansPro'),
        url(${sourcesanspro}) format('truetype');
    };

    @font-face {
        font-family: 'Source Sans Pro Bold';
        src: local('Source Sans Pro Bold'), local('SourceSansProBold'),
        url(${sourcesansprobold}) format('truetype');
    };

    @font-face {
        font-family: 'Lato';
        src: local('Lato'), local('Lato'),
        url(${lato}) format('truetype');
    };

    @font-face {
        font-family: 'Lato Bold';
        src: local('Lato Bold'), local('Lato Bold'),
        url(${latobold}) format('truetype');
    };

    @font-face {
        font-family: 'Arial';
        src: local('Arial'), local('Arial'),
        url(${arial}) format('truetype');
    };

    @font-face {
        font-family: 'Arial Bold';
        src: local('Arial Bold'), local('Arial Bold'),
        url(${arialbold}) format('truetype');
    };

    @font-face {
        font-family: 'Avenir Black';
        src: local('Avenir Black'), local('Avenir Black'),
        url(${avenir}) format('truetype');
    };

    @font-face {
        font-family: 'Avenir Book';
        src: local('Avenir Book'), local('Avenir Book'),
        url(${avenirbook}) format('truetype');
    };

    @font-face {
        font-family: 'Helvetica Neue';
        src: local('Helvetica Neue'), local('Helvetica Neue'),
        url(${helveticaneuebold}) format('truetype');
    };

    @font-face {
        font-family: 'Open Sans';
        src: local('Open Sans'), local('Open Sans'),
        url(${opensans}) format('truetype');
    };
    
    @font-face {
        font-family: 'Montserrat';
        src: local('Montserrat'), local('Montserrat'),
        url(${montserrat}) format('truetype');
    };
    
    @font-face {
        font-family: 'Montserrat Semibold';
        src: local('Montserrat Semibold'), local('Montserrat Semibold'),
        url(${montserratsemibold}) format('truetype');
    };
    
    @font-face {
        font-family: 'Merriweather Bold';
        src: local('Merriweather Bold'), local('Merriweather Bold'),
        url(${merriweatherbold}) format('truetype');
    };

    body {
        font-family: ${theme => theme.fontFamily};
    };
`;

const FontProvider = ({children}) => (
    <>
        <Fonts/>
        {children}
    </>
)

export default FontProvider;

FontProvider.propTypes = {
    children: PropTypes.node
};
