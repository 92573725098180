import React from "react";
import { Spinner as DefaultSpinner } from "react-bootstrap";
import styled from "styled-components";

const StyledSpinner = styled(DefaultSpinner)`
  color: ${({ theme }) => theme.spinner.color};
  animation-duration: 1500ms;
  border-width: 4px;
  width: 40px;
  height: 40px;
`;
const Spinner = () => <StyledSpinner animation="border" />;

export default Spinner;
