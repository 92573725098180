import defaultTheme from "./defaultTheme";
import { merge, cloneDeep } from "lodash";

const COLORS = {
  primary: "#093D39",
  secondary: "#0A5650",
  tertiary: "#96D2C8",
  focus: "#FF8FA0",
  focusLight: "#ceebe8",
  white: "#FFFFFF",
  black: "#000000",
  disabled: "#f1f1f1",
  success: "#009487",
};

const doktorseTheme = {
  fontFamily: "Avenir Book",
  colors: COLORS,
  button: {
    primary: {
      color: "#0c998c",
      hover: "#09766c",
    },
    secondary: {
      hover: COLORS.focusLight,
    },
    copy: {
      backgroundColor: COLORS.white,
      borderColor: COLORS.primary,
      hover: {
        backgroundColor: COLORS.focusLight,
      },
    },
  },
  horizontalLine: {
    color: COLORS.primary,
  },
  icon: {
    fill: COLORS.primary,
  },
  input: {
    backgroundColor: COLORS.focusLight,
    color: COLORS.primary,
    dangerColor: "#ff8fa0",
    border: {
      color: COLORS.tertiary,
    },
    focus: {
      backgroundColor: COLORS.focusLight,
      border: {
        color: COLORS.secondary,
        boxShadow: "0 0 0 0.2rem rgba(170, 43, 35,.25)",
      },
    },
    dropDown: {
      backgroundColor: COLORS.focusLight,
      listBackgroundColor: COLORS.white,
      color: COLORS.secondary,
      border: {
        width: "1px",
        color: COLORS.secondary,
      },
      hover: {
        color: COLORS.secondary,
        backgroundColor: COLORS.focusLight,
        border: COLORS.secondary,
      },
    },
  },
  navbar: {
    color: COLORS.white,
    backgroundColor: COLORS.secondary,
    border: {
      color: COLORS.secondary,
      width: "3px",
    },
    logo: {
      height: "32px",
      marginTop: "-6px",
    },
    link: {
      hover: {
        color: COLORS.primary,
        backgroundColor: COLORS.focusLight,
      },
    },
  },
  typography: {
    headerPrimaryColor: COLORS.primary,
    fontSize: "14px",
    font: "Avenir Book",
    boldFont: "Avenir Black",
    headingFont: "Avenir Black",
    linkColor: COLORS.primary,
  },
  checkbox: {
    border: {
      color: COLORS.primary,
    },
    check: {
      border: {
        color: COLORS.white,
      },
    },
    hover: {
      border: {
        color: COLORS.primary,
      },
    },
  },
  radioButton: {
    border: {
      color: COLORS.primary,
    },
    check: {
      border: {
        color: COLORS.white,
      },
    },
    hover: {
      border: {
        color: COLORS.primary,
      },
    },
  },
  spinner: {
    color: COLORS.primary,
  },
  feedback: {
    color: COLORS.white,
  },
  table: {
    row: {
      odd: {
        backgroundColor: "#f3f3f3",
        color: COLORS.black,
      },
      even: {
        backgroundColor: COLORS.white,
        color: COLORS.black,
      },
      hover: {
        backgroundColor: "#cbe7df",
        color: COLORS.success,
      },
    },
  },
};

export default merge(cloneDeep(defaultTheme), doktorseTheme);
